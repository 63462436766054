$black:#000;
$black01:rgba($black,.1);
$black02:rgba($black,.2);
$black05:rgba($black,.5);
$white:#fff;
$white01:rgba($white,.1);
$white03:rgba($white,.3);
$white05:rgba($white,.5);
$cream1:#FCFAF7;
$cream2:#FDFDFB;
$red:#ed1e24;;
$color1:#b266b2;
$color1-lt:#efdcea;
$color2:#660066;
$color3:#4c004c;
$dark3:#350035;
$dark3b:#260026;
$navy:#222b30;
$navy2:#12143C;
$gray:#69676d;
$gold:#c9b16a;
$gold2:#8c7b4a;
$logold:#bb985e;
$bodybg:$cream2; 

$border:1px solid rgba($black,.1);

// https://www.color-hex.com/color-palette/3384 // plum palette
// https://www.color-hex.com/color-palette/1010096 // plum palette
// https://www.color-hex.com/color-palette/116302

$m:'M PLUS 1', sans-serif;
$corm: 'Cormorant', serif;
$strings:'Strings';
$body:$m;
$heading:$strings;