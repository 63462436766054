@import '../../assets/global.scss';
@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.Social{display:inline-block;
  li {display: inline-block; margin:0 5px;
    &:last-child{margin-right:0;}
  }
  a, span {color: $black;}

  &.footer li{margin-left:0;}
  // svg {width:16px; height:16px;}
  
  // @include bp(tb) {
  //   svg {width:18px; height:18px;}
  // }
}