// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.ServicePage{@include sec-default();
  span.tm,em.tm{font-size:.6em;vertical-align:top;line-height:1.8em;}
  img.and, svg.and, h1 svg{display:inline;margin-top:-10px;padding:0 5px;}
  .arrow{width:25px;display:inline;padding:0 0 3px 2px;}

  .ServiceHeaderSimple{@include sec-default();padding:100px 0 35px;position:relative;text-align:center;
    h1,h2{color:$gray;}
    h1 svg{fill:$gray;}
    h1{line-height:1.3em;margin-bottom:0;}
    h1 img.and, h1 svg{width:55px;}

    h2{font-size:22px;letter-spacing:6px;text-transform:uppercase;font-weight:300;}
    h2 img.and{width:22px;padding:0;margin-top:-3px;}
    h2,p.hd{margin:10px 0 0;}

    @include bp(tb){padding:60px 0 30px;
      h1{font-size:50px;}
      h1 img.and, h1 svg{width:46px;}
      h2{font-size:19px;margin-top:0;letter-spacing:5px;}
    }

    @include bp(br){
      h1{font-size:45px;}
      h1 img.and, h1 svg{width:41px;}
      h2{font-size:18px;letter-spacing:4px;}
    }

    @include bp(og){padding:30px 0 0px;text-align:center;
      h1{font-size:35px;}
      h1 img.and, h1 svg{width:31px;}
      h2{font-size:15px;letter-spacing:3px;line-height:1.6em;margin-top:0px;}
    }
    @include bp(oc){padding-top:15px;
      h2{font-size:15px;}
      .medDef{width:97%;}
    }
  }

  .ServiceBody{background-color:rgba($bodybg,.2);position:relative;z-index:0;}//z-index:2;}
  
  .ServiceImgBlock{@include sec-bgimage2();position:relative;z-index:0; min-height:500px;
    &::before{@include before();background-color:rgba($black,.3);}

    .content{width:75%;max-width:650px;float:right;border-left:1px solid $white05;padding:200px 80px 200px 100px;}
    .ctext{@include center-block();}
    p{font-size:18px;color:$white;text-shadow:0px 1px $black05; font-weight:400}
    .textLink{font-size:18px;}
    .arrow.white{display:none;}

    @include bp(tl){
      .content{max-width:550px;padding:150px 60px 130px;}//padding-top:150px;padding-bottom:150px;}
    }
    @include bp(tw){min-height:350px;}
    @include bp(tb) {
      .content{max-width:500px;padding:100px 30px 80px 40px;}
    }
    @include bp(br){min-height:300px;
      .content{padding:80px 30px 60px 30px;}
    }
    @include bp(og){
      &::before{background-color:rgba($black,.6);}
      .content{width:85%;@include center-block();max-width:100%;padding:60px 0 50px;border-left:0;}
      .textLink{color:$white;
        &::after{background-color:$white;}
        &::before{background-color:$color1;}
      }
      .arrow.color{display:none;}
      .arrow.white{display:inline;}
    }
    @include bp(oc){min-height:200px;
      .content{width:90%;padding-top:40px;padding-bottom:30px;}
    }
  }

  .ServiceImgStack{display:block;padding:100px 0;position:relative;z-index:0;
    &::after{@include after();width:50%;right:0;background-color:$color1-lt;}
    &.withTitle{margin-top:80px;}

    .content{margin-left:-3%;max-width:430px;margin-top:14%;} 
    .imgMob{display:none;}

    h1,h2{color:$dark3;}
    h1 svg{fill:$dark3;}
    h1{line-height:1.3em;margin-bottom:0;}
    h1 img.and,h1 svg{width:58px;}
  
    h2{font-size:21px;letter-spacing:6px;text-transform:uppercase;font-weight:300;}
    h2 img.and{width:22px;padding:0;margin-top:-3px;}
    h2,p.hd{margin:10px 0 0;}
    P{font-weight:400;}


    @include bp(dt){p{font-size:18px;}}
    @include bp(tx){
      .content{margin-top:10%;}  
      h1{font-size:55px;}
      h1 img.and, h1 svg{width:42px;padding:0;}
      h2{font-size:19px;letter-spacing:5px;}
    }

    @media (max-width:1250px){h1{font-size:50px;}}
    @include bp(tw){.content{margin-top:7%;}}

    @include bp(tb){
      h1{font-size:50px;}
      h1 img.and,h1 svg{width:46px;}

      &.withTitle{margin-top:50px;}
    }
    @include bp(br){
      &.withTitle{padding:60px 0;margin-top:30px;}
      
      &.withText{padding-bottom:80px;
        .imageSide{display:none;}
        .imgMob{display:block;position:absolute;width:55%;left:4%;height:80%;top:10%;background-size:cover;background-position:center center;z-index:0;}
        .content{width:46%;float:right;position:relative;z-index:1;margin-left:0;margin-top:0 !important;}
      }
    }
    @include bp(og){padding:15px 0 10px !important;margin-top:0 !important;text-align:center;
      &::after{display:none;}

      .content,.imageSide, &.withText .imageSide{@include sec-default();width:100% !important;}
      .content{max-width:100% !important;margin:0 !important;}
      .imageSide{margin-top:10px;}

      &.withText .imgMob{display:none;}

      h1{font-size:35px;margin-bottom:0;}
      h1 img.and, h1 svg{width:33px;}
      h2{font-size:16px;letter-spacing:3px;line-height:1.6em;margin-top:0;}
    }
    @include bp(oc){h2{font-size:15px;}}
  }


  .treatmentUl{
    .treatLi{position:relative;z-index:2;float:left;width:49%;
      &.box2,&.box3,&.box4,&.box5{width:52%;}
      &.box2,&.box4{margin-left:-4%;}
      &.box3,&.box5{margin-right:-8%;float:right;}
      &.boxSolo{width:80%;float:right;
        .treatName{margin-bottom:5px;}
      }
      &.boxLong5{width:70%;margin-left:-5%;}
      &.boxShort6{width:30%;margin-right:-2%;float:right;margin-top:7%;}

      &.tl3a,&.tl3b,&.tl3c{padding-top:20px;}
      &.tl3a{width:50%;margin-left:-3%;padding-right:20px;}
      &.tl3b,&.tl3c{width:51%;float:right;margin-right:-3%;padding-bottom:15px;}
      &.tl3b{border-bottom:$border;}
    }

    .treatInner{position:relative;z-index:2;}
    .treatName{font-size:22px;font-weight:bold;line-height:1.2em;
      br:not(.showAll){display:none;}
    }
    .fancy{color:$color3;font-weight:600;}

    p.hd{font-size:25px;font-family:$corm;font-weight:bold;letter-spacing:1px;text-transform:uppercase;color:$color1}
    .treatPrice,.treatPrice0{color:$color2;font-weight:normal;margin-left:5px;font-size:17px;font-family:$body;letter-spacing:normal;text-transform:none;}
    .treatPrice0{margin-left:0;}
    .print{font-weight:normal; font-size:15px;}
    .textLink{font-size:16px;}
    span.smaller{font-size:15px;font-weight:400;}

    .row1,.row2,.row3,.row0,.rowMis{@include sec-default();position:relative;z-index:0;
      &::before{@include after();left:0;border-right:$border;}
    }
    .row1{float:right;margin-right:-15%;
      &::before{width:51.5%;}
    }
    .row2{margin:100px 0 100px -12%; margin-bottom:80px;
      &::before{width:50%;}
    }
    .rowMis{margin:20px -5% 0 0px;float:right;
      &::before{width:68%;}
    }
  
    @media (max-width:1500px){
      .row1{margin-right:-8%;}
      .row2{margin-left:-9%;}
    }
    @include bp(tx){
      .row1{margin-right:-6%;}
      .row2{margin-left:-7%;}
    }
    @include bp(tw){
      .treatLi{
        &.box2,&.box3,&.box4,&.box5{width:49%;}
        &.box2,&.box4{margin-left:0;padding-right:15px;}
        &.box3,&.box5{margin-right:0;padding-left:20px;}
        &.boxSolo{float:left;}

        &.boxLong5{margin-left:0;padding-right:20px;}
        &.boxShort6{margin-right:-3%;}

        &.tl3c{margin-bottom:20px;}
      }

      .treatName{font-size:20px;}
      .treatPrice{font-size:16px;}


      .row1,.row2{border-bottom:$border;padding-bottom:30px;
        &::before{height:90%;bottom:10%;width:50%;}
      }
      .row1{margin-right:0;}
      .row2{margin-left:0;margin-top:40px;margin-bottom:40px;}
      .rowMis{margin-right:0;margin-top:10px;
        &::before{width:70.5%;}
      }
    }
    @include bp(tb){
      .treatLi{
        &.tl3a{margin-left:-1%;}
        &.tl3b,&.tl3c{margin-right:-2%;}
      }

      .rowMis{margin-top:0;}
    }
    @include bp(br){
      .treatLi{padding:0;margin-bottom:20px;margin-left:-7px;
        &.box2,&.box3,&.box4,&.box5, &.boxLong5, &.tl3a,&.tl3b,&.tl3c{width:100%;}
        &.box2,&.box4,&.boxLong5, &.tl3a{padding-right:0;margin-left:-30px;}
        &.box3,&.box5{padding-left:0;margin-right:-30px;}
        &.boxSolo{width:85%;margin-bottom:30px;max-width:320px;margin-left:-30px;}
        
        &.boxShort6{width:100%;float:left;margin:0 0 50px;padding-left:30px;}

        &.tl3a,&.tl3b,&.tl3c{padding:0;}
        &.tl3b{margin-right:-30px;border-bottom:0;}
        &.tl3c{margin-right:0;margin-left:-30px;margin-bottom:90px;}

        .treatName{font-size:19px;margin-bottom:10px;}
        .treatPrice{font-size:15px;}
        p:not(.treatName){font-size:16px;}
      }

      .row1,.row2,.row3,.rowMis{border:none;padding:0;
        &::before{display:none;}
      }
      .row2{margin:0;}
    }
    @include bp(md){
      .treatLi{
        &.box2,&.box4, &.boxLong5, &.tl3a,&.tl3c{margin-left:-3%;}
        &.box3,&.box5, &.tl3b{margin-right:-3%;}
      }
    }
    @include bp(og){
      .treatLi{width:100% !important;margin-left:0;padding:0 15px !important;
        &.boxSolo{max-width:100%;margin-bottom:0;}
        &.boxShort6{margin-bottom:0;
          p{padding-left:15px;}
        }

        &.tl3c{margin-bottom:0px;}
      }
    }
    @include bp(oc){
      .treatLi{width:100% !important;margin-left:0 !important;margin-right:0 !important;padding:0 15px !important;
        &.boxShort6 p{padding-left:0;}
      }
    }
  }

  .treatBlk{position:relative;
    .treatName{line-height:1.1em;padding-top:30px;text-transform:uppercase;letter-spacing:1px;font-size:32px;font-family:$corm;font-weight:bold;
      span{font-size:50px;font-family:$heading;letter-spacing:3px;text-transform:none;font-weight:normal;color:$color2;
        @include rotate(-15deg);position:absolute;margin-left:-45px;margin-top:-40px;
      }
    }

    .treatPrice{color:$color1;font-weight:800;margin-left:10px;font-size:17px;}
    .print{font-weight:normal;font-size:15px;}
    .textLink{font-size:16px;}
    span.smaller{font-size:15px;font-weight:400;}

    @include bp(tb){
      .treatName{font-size:28px;
        span{margin-top:-30px;font-size:47px;}
      }
    }
    @include bp(br){
      .treatName{font-size:22px;margin-bottom:10px;
        span{margin-top:-21px;font-size:36px;}
      }
    }
    @include bp(oc){
      .treatName{font-size:20px;padding-top:0;
        span{font-size:31px;@include rotate(0);margin-top:0;margin-left:0;position:relative;letter-spacing:0;}//font-size:40px;}
      }
    }
  }

  .osWrap{position:relative;z-index:0;
    .cfBox{position:absolute;z-index:3;border-radius:50%;background-color:$black;text-align:center;
      width:300px;height:300px;right:40px;top:-160px;padding-top:75px;

      p{color:$white;width:80%;@include center-block();}
      p:not(.cat){font-size:15.5px;}
    }
  
    @include bp(tw){.cfBox{right:20px;}}
    @include bp(tb){
      .cfBox{right:15px;width:275px;height:275px;padding-top:65px;top:-140px;
        p{width:90%;}
        p:not(.cat){font-size:15px;}
      }
    }
    @include bp(br){.cfBox{right:50%;margin-right:-138px;top:-135px;}}
    @include bp(og){
      .cfBox{position:relative;@include sec-default();height:auto;top:auto;border-radius:0;padding:20px 10px 25px;right:0;margin-right:0;margin-top:10px;
        p{width:95%;max-width:550px;margin-bottom:0;}
      }
    }
  }


  @media (min-width:1801px){
    .xxlColor1Lt{@include sec-default();background-color:$color1-lt;}
    .xxlWrap{max-width:1700px;@include center-block();}
  }
  .OtherServices{padding:50px 5% 50px;  background-color:$color1-lt;position:relative;z-index:1;
    &::before{@include abs();width:40%;left:30%;top:0;height:100%;@include sec-bgimage('./images/otherServices.jpg');background-size:cover;}

    .mtitle{display:none;}
    .titleRow{@include sec-default();min-height:170px;}
    p.title{position:absolute;left:0;width:100%;color:$white;text-align:center;font-family:$heading;letter-spacing:15px;font-size:170px;opacity:.85;text-shadow: 1px 1px rgba($color1,.5);}
    ul{float:left;margin-left:-2%;position:relative;z-index:1;}
    li{margin-bottom:15px;
      &:last-child{margin-bottom:0;}
    }
    li.addit{display:block;}
    li.current{display:none;}
    a{color:$black;font-size:19px;}

    // .reviews{max-width:380px;margin-top:30px;padding-top:10px;padding-left:15px;position:relative;
    //   &::before,&::after{@include abs();width:30px;height:30px;font-size:30px;font-weight:bold;}
    //   &::before{content:'“';top:0;left:-10px;}
    //   &::after{content:'”';bottom:20px;right:0;}

    //   p.quote{font-style:italic;}
    //   p.cat{letter-spacing:3px;font-size:14px;}
    // }

    .reviews{max-width:380px;margin-top:30px;padding-top:10px;padding-left:15px;
      p.quote{font-style:italic;position:relative;z-index:0;
        &::before,&::after{@include abs();width:30px;height:20px;font-size:30px;font-weight:bold;font-style:normal;}
        &::before{content:'“';left:-20px;top:-5px;}
        &::after{content:'”';text-align:right;margin-top:3px;}
      }
      p.cat{letter-spacing:3px;font-size:14px;}
      br{display:none;}
    }



    @media (max-width:1600px){.reviews{margin-right:-2%;}}
    @media (max-width:1500px){
      p.title{letter-spacing:1px;font-size:145px;}
      .reviews{margin-right:-3%;}
    }

    @include bp(tl){
      .reviews{margin-right:-4.5%;padding-top:6px;}
    }

    @include bp(tx){
      p.title{font-size:115px;letter-spacing:5px;}
      .reviews{max-width:300px;margin-right:-2%;
        p.quote{font-size:16px;
          &::before,&::after{font-size:25px;}
          &::before{top:0;}
        }
        p.cat{font-size:13px;}
        br{display:none;}
      }
    }

    // @include bp(tw){.reviews{margin-right:-2%;}}
    @include bp(tw){.reviews{margin-right:-4%;}}

    @include bp(tb){
      .titleRow{margin-top:70px;}
      .reviews{max-width:280px;margin-top:0px;margin-right:-4%;
        br{display:block;}
      }
    }

    @include bp(br){padding:35px 5%;
      &::before{width:50%;left:auto;right:0;}
      .titleRow{margin-top:50px;min-height:110px;}
      p.title{font-size:95px;}

      a{font-size:18px;}


      &::after{@include abs();width:50%;height:50%;max-width:430px;max-height:250px;bottom:0px;right:0;background:radial-gradient(rgba($black,.7) 20%, transparent 70%);}
      .reviews{max-width:360px;text-shadow:1px 0 $black;margin-top:20px;margin-right:-2%;
        p.quote::before,p.quote::after,p{color:$white;}
        
        p.quote{font-weight:500;
          &::before,&::after{width:20px;font-size:20px;}
        }
        br{display:none;}
      }
    }
    
    @include bp(tn){ 
      .reviews{margin-right:-3%;
        p.quote::before{left:-15px;}
      }
    }

    @include bp(og){
      .titleRow{display:none;}
      .mtitle{@include sec-default();}
      
      ul{margin-left:0;}
      p.cat{margin-bottom:15px;font-size:14px;color:$color3;}
      a{font-size:17px;}


      &::before{width:40%;background-image:url('../../assets/images/otherServicesMob.jpg');}
      &::after{display:none;}
      .list{@include sec-default();}
      .reviews{display:block;width:75%;float:left;text-shadow:none;padding-left:10px;
        p.quote::before,p.quote::after,p{color:$color3;}
        p.quote{margin-bottom:10px;
          // &::after{bottom:35px;}
        }
      }
    }

    @include bp(oc){padding:20px 5%;
      &::before{display:none;}

      .list{text-align:center;}
      ul{@include center-block();}
      .reviews{max-width:100%;width:100%;}
    }
  }


  &.massage-therapy{
    .ServiceImgBlock{background-position:top center;
      &::before{display:none;}
    }
    .ServiceBody{padding:150px 0 200px;}

    .image{width:48%; margin-left:-8%;}
    .treatList{width:65%; margin-right:-15%; margin-top:80px;}

    .treatBlk{width:30%; display:inline-block;vertical-align:top;
      p.treatName{font-size:22px;margin-bottom:5px;
        span{font-size:40px;margin-left:-31px;margin-top:-31px;}
        &.bmargin0{margin-bottom:0;}
      }

      // &.warm{margin-left:6%;}
      // &.pre{float:right; margin-right:-2%;}
      &.m3{margin-bottom:40px;margin-top:10px;}
      &.l2 p.treatName span{margin-left:-36px;margin-top:-34px;}

      &.deep,&.swed{margin-left:20%;margin-right:10%;}
    }


    .ServiceImgStack{
      &::after{right:auto;left:0;}
      .content{width:35%;margin-left:0;max-width:480px;float:left;}
      .imageSide{float:right;}

      @media (max-width:1500px){.content{margin-left:-3%;}}
      @media (max-width:1250px){
        .content{width:40%;max-width:100%;margin-top:10%;}
        .imageSide{margin-right:-3%;}
        h2{font-size:18px;}
      }

      @include bp(tb){
        h1{font-size:48px;}
        h2{font-size:17px;}
      }
      @include bp(br){
        h1{font-size:42px;}
        h2{font-size:16px;letter-spacing:4px;}
      }
      @media (max-width:900px){
        h1{font-size:37px;}
        h2{font-size:15px;letter-spacing:2px;}
      }
      @include bp(og){
        h1{font-size:35px;}
        .imageSide{margin-right:0;}
      }
    }

    .aoTitle{@include sec-default();text-align:center;position:relative;z-index:0;margin:-20px 0 35px;
      &::before{@include after();left:0;height:49%;border-top:$border;}
      span{background-color:$white;font-size:19px;font-weight:bold;padding:0 15px;text-transform:uppercase;letter-spacing:4px;}
    }
    .addons .treatBlk{
      &.myo2{margin-left:16%;}
      &.cup2{width:40%;float:right;}
    }

    @include bp(tl){.treatList{width:60%;margin-right:-10%;}}

    @include bp(tw){
      .image{margin-left:-5%;}
      .treatList{width:55%;margin-right:-5%;}

      .treatBlk{
        // &.warm{margin-left:3%;}
        // &.pre{margin-right:4%;}
        &.cup{margin-left:8%;}
        &.deep,&.swed{margin-left:15%;margin-right:15%;}
      }

      .addons .treatBlk.myo2{width:35%;margin-left:12%;}
    }

    @include bp(tb){
      .ServiceBody{padding:60px 0 150px;}
      .image{margin-left:0; width:42%;}
      .treatList{margin-right:-3%;width:55%;}

      .treatBlk{
        p.treatName{font-size:20px;
          span{font-size:35px;margin-left:-27px;}
        }

        &.warm,&.rain{margin-left:3.5%;}
        &.oil,&.pre{margin-right:2%;}
      }
    }

    @include bp(br){
      .image{@include center-block();margin:20px auto;}
      .treatList{width:100%;max-width:600px;@include center-block();margin-top:0;padding-left:31px;}

      .treatBlk{
        p.treatName{font-size:19px;
          span{font-size:34px;}
        }
        p:not(.treatName){font-size:16px;}

        &.t2,&.m3{width:25%;margin-left:0;margin-right:0;margin-top:0;}
      }

      .addons .treatBlk.myo2{margin-left:14%;}
    }

    @include bp(og){.ServiceBody{padding:30px 0 0;}}

    @include bp(oc){
      // .aoTitle{margin-bottom:20px;}

      // .treatList{max-width:450px;}
      // .treatBlk{
      //   &.t2,&.m3{width:45%;}
      // }

      .addons .treatBlk{text-align:center;
        &.myo2,&.cup2{width:100%;margin-left:0;}
        &.cup2{margin-bottom:20px;}        
      }
    // }

    // @include bp(ph){
      .ServiceBody{padding-top:0;}
      .treatList{padding-left:0;}

      .treatBlk{width:100%;text-align:center;float:none !important;margin:0 auto !important;padding:15px 0 5px;
        &.t2,&.m3{width:100%;}
        &.warm,&.pre{background-color:#f7f7f7;}
        p.treatName{font-size:18px;padding-top:0;color:$color3;text-decoration:underline;
          span{font-size:18px;font-family:$corm;@include rotate(0);position:relative;margin:0 !important;letter-spacing:1px;text-transform:uppercase;font-weight:bold;}
        }
      }

      .aoTitle{margin:15px 0 0px;
        span{font-size:17px;}
      }
      .addons .treatBlk.cup2{padding-top:0;}

    }
  }

  &.browLash{
    h1{max-width:500px;}
    .ServiceImgBlock::before{display:none;}
    .ServiceBody{padding:80px 0 120px;}

    .two{text-align:right;margin-top:-20px;}
    .image{margin-left:-7%;margin-top:30px;}
    .three{margin-top:80px;margin-left:5%;max-width:300px;
      p.disc{font-size:20px;text-transform:uppercase;font-weight:600;font-family:$corm;margin-bottom:0px;}
      p{color:$color2;}
    }


    // TESTING NEW LAYOUT
    .ServiceImgStack{
      @media (max-width:1100px){
        h1{font-size:45px;}
        h1 img.and{width:40px;}
      }

      @include bp(tw){.content{margin-top:10%;}}
      @include bp(tb){
        h1{font-size:42px;}
        h1 img.and{width:37px;}
      }
      @include bp(br){
        .content{width:42%;margin-left:-5%;}
        .imageSide{width:62%;}
        h1{font-size:40px;}
        h1 img.and{width:31px;}
      }

      @include bp(og){
        h1{font-size:38px;}
        h1 img.and{width:32px;}
      }
    }
    // TESTING NEW LAYOUT



    @include bp(tw){
      .one{margin-left:5%;}
      .image{margin-left:-5%;}
      .three{margin-left:15%;margin-top:60px;}
    }
    @include bp(tb){
      // h1{max-width:100%;}
      .ServiceBody{padding:60px 0 70px;}
      .three{margin-left:10%;}
    }

    @include bp(br){
      .ServiceBody{padding:35px 0 140px;}
      .two{margin-top:0;}
      .image{margin-left:-4%;}
      .three{max-width:100%;margin-left:2%;
        p.disc{font-size:19px;line-height:1.3em;}
        p.small{font-size:16px;}
      }
    }

    @media (max-width:959px){
      .ServiceBody{padding-bottom:170px;}
      .one{width:90%;}
      .two{margin-top:50px;}
      .three{margin-top:30px;width:65%;margin-left:0;float:right;}
    }

    @include bp(tn){
      .two{width:64%; margin-top:50px;}
      .three{@include sec-default();text-align:center;margin-top:50px;}
    }
    @include bp(og){
      h1{max-width:400px;@include center-block();}
      .ServiceBody{padding:30px 0 0px;}
    }
    @include bp(oc){
      .one,.two{@include sec-default();}
      .one{margin-left:0;}
      .image{display:none;}
      .two,.three{margin-top:20px;}
      .three p.disc{font-size:18px;padding:0 10px;}
    }
  }

  &.skin-care{
    h1{max-width:800px;}
    .bodyUl{@include sec-default();
      .row3::before{width:57.3%;}
      .left{width:54%;}
      .right{width:38%;}
      .treatLi{@include sec-default();}
      .bl1,.br1{margin-bottom:40px;}
    }
    .ServiceImgBlock p{font-weight:600;font-size:19px;text-shadow:.3px .7px rgba($black,1);}
  
    .tmarg{margin-top:25%;}
    .dotList li::before{background-color:$color2;}
    p.hd{padding-left:10px;}

    .cblock{margin-top:20px;margin-bottom:40px;text-align:center;max-width:600px;}


    .ServiceHeaderSimple h1{@include center-block();}
    .ServiceBody{padding-bottom:100px;}

    @include bp(tl){
      .bodyUl{padding-bottom:40px;}
      .cblock{text-align:left;}
    }
    @include bp(tw){
      .bodyUl{padding-bottom:50px;
        .bl1,.br1{padding-bottom:20px;border-bottom:$border;margin-bottom:30px;}
      }
    }
    @include bp(tb){.bodyUl{padding-bottom:30px;}}
    @include bp(br){
      .ServiceBody{padding-top:0;}
      .treatmentUl{width:80%;max-width:670px;@include center-block();}
      .bodyUl{padding-bottom:0px;
        .row3{margin-bottom:30px;}
        .left,.right{width:100%;float:left;}
        .treatLi{width:95%;margin-bottom:20px;}
        .bl1,.br1{padding-bottom:0px;border-bottom:none;margin-left:-2%;}
        .bl2,.br2{float:right;margin-right:-4%;}
      }
      .cblock{margin-top:0px;margin-bottom:100px;float:none;text-align:center;}
      p.hd{font-size:22px;margin-bottom:5px;}
    }
    @include bp(md){.treatmentUl{width:90%;}}
    @include bp(og){
      .ServiceBody{padding-bottom:15px;}
      .cblock{margin-bottom:0;}

      .bodyUl{
        .row3{margin-bottom:0px;}
        .treatLi{width:100%;}
        .bl2,.br2{margin-right:-2%;}
        .br2{margin-bottom:0px;}
      }
    }
    @include bp(oc){
      .ServiceImgBlock p{font-size:17px;}
      .treatmentUl{width:100%;}
    }
  }

  &.laser-treatments{    
    // .ServiceHeaderSimple h2{font-size:21px;padding-left:10px;}
    .ServiceImgStack{margin-bottom:60px;
      .content{max-width:400px;margin-top:12%;}
      .arrow.white{display:none;}
    }

    .ServiceBody{padding:20px 0 120px;}
    .headline{position:absolute;bottom:40px;right:30px;
      p{font-size:45px;font-family:$heading;color:$color2;line-height:1em;text-transform:capitalize;margin-bottom:0;}
      .headline p span{font-size:30px;}
    }

    .row1,.row2, .row{@include sec-default();clear:both;}
    .row+.row{margin-top:60px;}
    .imgRowMobile{display:none;}

    .textLay{margin-right:-2%;}
    .row1 .textLay{margin-top:18%;}
    .row2 .textLay{margin-top:3.5%;}
    
    .rowSolo{
      .long{width:54%;float:right;margin-top:-90px;margin-right:1%;}
      .short{width:35%;float:left;margin-top:3.5%;}
      span.cat, p.cat{letter-spacing:2px;}
    }
    
    .treatBlk.tnc .treatName span{margin-left:-35px;margin-top:-30px;@include rotate(-20deg);}

    @media (min-width:1025px){.ServiceHeaderSimple h2{letter-spacing:4px;font-size:18px;}}
    @include bp(xl){.headline p span{text-transform:uppercase;font-weight:bold;font-family:$corm;letter-spacing:4px;}}
    @media (max-width:1500px){
      .headline{bottom:20px;right:20px;
        p{font-size:41px;}
        p span{font-size:25px;padding-left:4px;}
      }
    }
    @include bp(tl){.ServiceImgStack .content{margin-top:9%;}}
    @include bp(tw){
      .ServiceBody{padding-top:0px;}
      .ServiceImgStack .content{width:40%;margin-left:-5%;}
      .headline{bottom:15px;right:15px;
        p{font-size:37px;}
        p span{font-size:24px;padding-left:0px;}
      }
      .textLay{margin-right:-1%;}

      .rowSolo{
        .long{margin-top:-75px;}
        .short{margin-left:2%;}
        span.cat, p.cat{letter-spacing:1px;}
      }
    }
    @include bp(tb){
      .headline{right:10px;}
      .ServiceImgStack{margin-bottom:40px;}
      .ServiceHeaderSimple h2{font-size:18px;letter-spacing:2px;}

      .ServiceBody{padding-bottom:80px;}
      .textLay{margin-right:0;}
    }

    @include bp(br){
      .ServiceImgStack{
        .content{width:46%;margin-left:0;} 
        .imgMob{background-position:bottom center;}
      }
      .headline{@include sec-default();position:relative;right:auto;bottom:auto;margin-top:20px;  text-align:center;
        p{font-size:35px;line-height:1.4em;}
        br.br1{display:none;}
        p span{font-size:inherit;}
      }
      .ServiceHeaderSimple h2{font-size:16px;letter-spacing:1px;}

      .textLay{width:45%;margin-right:-1%;}
      .treatBlk.tnc .treatName span{margin-left:-30px;margin-top:-25px;}

      .row+.rowSolo{margin-top:0px;}
      .rowSolo{
        .long,.short{width:90%;@include center-block();}
        .long{margin-top:40px;}
        .short{text-align:right;margin-top:30px;margin-bottom:70px;}
      }
    }
    @include bp(nn){
      .ServiceBody{padding-bottom:120px;}
      .row1 .textLay{margin-top:15%;}
      .row2 .textLay{margin-top:2%;}
    }
    // @include bp(md){.row1{margin-bottom:30px;}}
    @include bp(md){.row+.row{margin-top:30px;}}
    @include bp(og){
      .ServiceHeaderSimple h2{font-size:15px;}
      .ServiceImgStack{margin-bottom:0px;}
      .headline br.br2, .imageLay{display:none;}

      .headline p{font-size:26px;}
      
      .ServiceBody{padding-bottom:30px;}
      .textLay, .imgRowMobile{@include sec-default();}
      .textLay{width:100%;max-width:500px;@include center-block();}

      .row+.row{margin-top:20px;}
      .row+.rowSolo{margin-top:0px;}
      .row1 .textLay{margin-top:20px;}
      .row2 .textLay{margin:0 auto 40px;text-align:right;}
      .rowSolo{
        .long,.short{width:100%;max-width:500px;}
        .long{margin-top:0px;}
        .short{margin-bottom:50px;}
      }
    }
    @include bp(oc){
      .ServiceBody{padding-bottom:15px;}
      .treatBlk.tnc .treatName span{margin-left:inherit;margin-top:inherit;}
    }
    @include bp(sm){.ServiceHeaderSimple h2{font-size:14px;letter-spacing:1px;padding-left:5px;}}

  }

  &.enhance{
    h2{font-size:18px;  display:none;}
    .cet{@include sec-default();}
    .cet1{
      .row{width:100%;display:block;}
      p{margin-bottom:30px;}
    }
    .cblock{width:90%;margin:30px auto 50px;
      &.sm{max-width:630px;}
      &.mid{max-width:650px;}

      p{font-size:19px;line-height:1.8em;}
      span.tm{line-height:29px;}
    }
    h4{font-size:18px;margin-bottom:10px;}


    // TESTING NEW LAYOUT
    .ServiceBody{padding-bottom:100px;}
    .ServiceImgStack .content{max-width:450px;margin-top:10%;}
    // TESTING NEW LAYOUT

    @include bp(tx){
      .cet1 .last{margin-bottom:40px;}
      .cblock.sm{max-width:650px;}
      
      .ServiceImgStack .content{margin-top:5%;}
    }
    @include bp(tw){
      .cet1{padding:0 30px;}
      .cet1 .last{max-width:750px;}
      .cblock{margin:0 0 0 2%;
        &.sm{max-width:100%;width:70%;}
        &.mid{max-width:100%;
          p:last-child{width:75%;}
        }
      }
    }

    @media (max-width:1115px){br.vBr{display:none;}}

    @include bp(tb){.ServiceImgStack .content{margin-top:3%;}}
    @include bp(br){
      .ServiceImgStack .imgMob{background-position: center right;}
      .ServiceBody{padding-top:0;}
      .cet1 .last{width:100%;max-width:100%;margin-bottom:55px;}

      .cblock{margin:0 auto 100px;text-align:center;
        &.sm{width:90%;}
        &.mid p:last-child{width:100%;}
        p{font-size:17px;line-height:1.6em;}
        span.tm{line-height:1.8em;}
      }

      br.vBr{display:block;}
    }

    @media (max-width:890px){br.vBr{display:none;}}


    @include bp(tn){.cet1 .last{margin-bottom:80px;}}
    @include bp(og){
      .ServiceBody{padding-bottom:0;}

      .col{width:100%;}
      .left.pull,.right.pull{margin:0;}

      .cet1{padding:0;
        .last{margin-bottom:0;}
        p{margin-bottom:20px;}
      }
      .cblock{margin-bottom:0;}
    }
  }
}