// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.ProductsPage{@include sec-default();padding-bottom:30px;position:relative;z-index:0;
  @include bp(og){padding-bottom:0px;}

  .ServiceHeaderSimple{@include sec-default();padding:100px 0 20px;
    h1{line-height:1.3em;margin-bottom:0;max-width:700px;color:$gray;
      span{float:right;}
    }

    @include bp(tl){
      .medDef{max-width:950px;}
      h1{@include center-block();}
    }
    @include bp(tw){h1{font-size:55px;}}
    @include bp(tb){padding-top:80px;
      h1{font-size:50px;}
    }

    @include bp(br){padding-top:50px;
      .medDef{max-width:650px;}
      h1{font-size:45px;max-width:100%;width:90%;}
    }

    @include bp(og){padding:30px 0 10px;
      .medDef{max-width:450px;}
      h1{font-size:35px;line-height:1.1em;width:100%;}
    }

    @include bp(oc){h1{font-size:33px;}}

    @media (max-width:350px){text-align:center;
      h1{font-size:30px;
        span{float:none;}
      }
      br{display:none;}
    }
  }

  .fullImg .insideXl{width:100%;max-width:100%;}

  .customWrap{@include sec-default();text-align:center;
    .innerWrap{width:80%;float:left;display:block;margin-top:-80px;background-color:$navy;position:relative;z-index:0;
      &::after{@include after();left:0;height:170px;background-color:$bodybg;}
    }
    
    .text0{@include sec-default();padding:50px 0 40px;}
    .innerText{@include center-block();width:90%;max-width:720px;}

    p{font-size:18px;line-height:1.7em;color:$white;
      b{font-weight:800;letter-spacing:.5px;}
    }
    p.after{position:relative;z-index:0;padding-bottom:25px;
      &::after{@include abs();width:5px;height:5px; bottom:1px;left:50%;margin-left:2.5px; background-color:$white;border-radius:50%;opacity:.7;}
    }

    img{width:90%;@include center-block();}
    @include bp(lg){
      .textLink{color:$white;
        &::after,&::before{background-color:$white;}
      }
    }

    @include bp(tw){.innerWrap{width:90%;margin-top:-50px;}}
    @include bp(br){
      .innerWrap{width:95%;}
      .innerText{max-width:630px;}
      p{font-size:17px;}
      img{width:95%;}
    }

    @include bp(og){
      .innerWrap{background-color:transparent;}
      .innerText{max-width:600px;}
      .medDef{width:95%;}
      .text0{margin-top:40px;padding:35px 0 15px;}
      img{width:100%;}

      p{color:$black;line-height:1.5em;}
      p.after::after{background-color:$black;}
    }

    @include bp(oc){
      .text0{padding:30px 0 10px;}
    }
  }


  .pageWrap{@include sec-default();
    .innerWrap{width:60%;max-width:900px;float:left;display:block;margin-top:-80px;background-color:$dark3b;position:relative;z-index:0;}
    
    .text0{@include sec-default();padding:50px 0 40px;
      p{color:$white;@include center-block();width:90%;max-width:550px;font-size:19px;}
    }

    p{line-height:1.7em;
      b{font-weight:800;letter-spacing:.5px;}
    }

    .imagePlus{padding:80px 0;
      .left{margin-left:-6%;margin-top:15%;}
      .right{margin-right:-6%;}

      p{font-size:19px;}

      p.ogShow,img.mobile{display:none;}
    }
    .banner{@include sec-default();padding:20px 0;text-align:center;background-color:$dark3;position:relative;z-index:0;
      p{margin-bottom:0;color:$white;font-size:20px;}
      .textLink{color:$white;
        &::after,&::before{background-color:$white;}
      }
    }
  
    @media (max-width:1300px){
      .imagePlus{
        .left{margin-left:-4%;}
        .right{width:70%;margin-right:-4%;}
      }
    }

    @include bp(tw){
      .imagePlus{
        .left{width:34%;margin-left:-2%;}
        .right{width:67%;margin-right:-2%;}
        p{font-size:18px;}
      }
    }

    @include bp(tb){
      .innerWrap{width:70%;margin-top:-50px;}

      .imagePlus{
        .left{width:31%;margin-left:0;margin-top:12%;}
        .right{width:64%;margin-right:0;}
        p{font-size:17px;}
      }
    }

    @include bp(br){
      .innerWrap{width:80%;max-width:670px;margin-top:-35px;}
      .text0 p{font-size:18px;}

      .imagePlus{
        .medDef{width:90%;}
        .left{margin-left:-1%;width:40%;}//margin-top:12%;}
        .right{margin-right:-1%;width:58%;}

        img.fw{display:none;}
        img.mobile{display:block;}
      }

      .banner p{font-size:18px;}
    }

    @media (max-width:800px){.imagePlus .left{margin-top:8%;}}

    @include bp(og){text-align:center;
      .innerWrap{width:100%;max-width:100%;margin:0 auto;float:none;background-color:transparent;}
      .text0{padding:30px 0 0;
        p{font-size:17px;color:$black;max-width:600px;}
      }

      .imagePlus{padding:0;
        .left{width:100%;margin:0;max-width:600px;@include center-block();}
        .right{@include sec-default();}

        p.ogShow{display:block;}
        p.after{position:relative;z-index:0;padding:25px 0;
          &::before,&::after{@include abs();width:5px;height:5px;left:50%;margin-left:2.5px;background-color:$black;border-radius:50%;opacity:.7;}
          &::before{top:2px;}
          &::after{bottom:1px;}
        }

        img.fw{@include center-block();width:95%;max-width:600px;margin-top:20px;}
        img.mobile{display:none;}
      }

      .banner{display:none;}
    }
  }
}