@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.TabSection{@include sec-default();
  .tabNav{@include sec-default();margin-left:0;
    &.centerNav{text-align:center;}

    li{display:inline-block;padding:0;margin:0 30px;}

    &.reg{padding-left:0;
      li:first-child{margin-left:0;}
      li:last-child{margin-right:0;}
    }
    button{text-transform:uppercase;padding:20px 0;font-size:20px;font-weight:400;letter-spacing:1px;line-height:1em;
      span{position:relative;}
      b{font-family:$heading;font-size:33px;letter-spacing:3px;text-transform:none;line-height:1.4em;}
      em{color:inherit;font-style:normal;padding-left:2px;line-height:1em;}

      br{display:none;}
    }

    &.dec{width:100%;padding:0;position:relative;z-index:0;background-color:$color3;text-align:center;
      button{color:$white;padding:10px 0;}
      span{font-size:19px;}

      li::before{@include after();left:0;bottom:20px;height:2px;width:0%;background-color:$white;opacity:.5;transition:width 0.5s ease;display: block;}
      li:focus::before, li:hover::before{width:100%;}
    }

    @include bp(tb){
      button{font-size:19px;}
      button b{font-size:31px;letter-spacing:2px;}
    }
    @include bp(br){padding:20px 0;
      li{margin:0 20px;}
      button{font-size:18px;}
      button b{font-size:29px;}
    }
    @include bp(og){padding:5px 0;
      li{margin:15px;}
      li::before{bottom:0 !important;}
      button{padding: 0;}

      &.dec{
        span{font-size:17px;}
        b{font-size:inherit;font-family:inherit;letter-spacing:inherit;text-transform:inherit;font-weight:600;line-height:inherit;}
      }
    }

    @include bp(mb){
      &.dec{padding:5px 0 10px;
        li{@include center-block();margin:10px auto 5px;}
        span{width:auto;}
        button{padding-bottom:0;}
      }

    }
  }

  .tabContent{@include sec-default();margin-top:50px;
    @include bp(tb){margin-top:30px;}
    @include bp(br){margin-top:20px;}
  }



  &.alt{
    .tabContent,.tabNav{display:inline-block;}
    .tabNav{width:25%;max-width:300px;float:left;text-align:left;
      &::before{display:none;}
      li{@include sec-default();margin:0;}
    }
    .tabContent{width:75%;float:right;margin-top:30px;}
  }

  &.serviceCosmetic{
    .tabNav.dec li::before{bottom:10px;}
    @media (min-width:768px){.tabNav.dec{padding:15px;}}
  }

  // @include bp(mb){&.serviceSkin .tabNav.dec li{max-width:200px;}}
}