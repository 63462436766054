@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Home{@include sec-default();position:relative;z-index:0;
  .hsec1{position:relative;z-index:1;margin-bottom:10px;padding-top:80px;
    .insideAlt{width:85%;}
    h1{font-size:75px;padding-left:30px;line-height:1.3em;margin-bottom:0px;max-width:850px;
      &{color:$gray;}
      svg{fill:$gray;}
      img,svg{width:50px;display:inline;margin-top:-12px;}
      span{position:relative;font-size:80px;float:right;}
    }
    h2{font-size:18px;color:$gray;text-transform:uppercase;letter-spacing:3px;font-weight:300; display:none;}

    @include bp(su){.insideAlt{width:70%;}}
    @media (max-width:1300px){
      .insideAlt{width:93%;}
      h1{max-width:750px;}
    }
    @include bp(tw){
      h1{max-width:650px;font-size:70px;
        span{font-size:75px;}
        img,svg{width:45px;}
      }
    }
    @include bp(br){
      h1{max-width:550px;font-size:60px;
        span{font-size:65px;}
        img, svg{width:35px;}
      }
    }

    @media (max-width:900px){
      .insideAlt{width:96%;}
      h1{max-width:480px;font-size:52px;
        span{font-size:60px;}
        img,svg{width:31px;}
      }
    }
    @include bp(og){padding-top:30px;margin-bottom:5px;text-align:center;
      h1{font-size:45px;@include center-block();padding-left:0;
        span{font-size:45px;float:none;}
        img, svg{width:28px;}
        br{display:none;}
      }
      h2{display:block;}
    }

    @include bp(xs){
      h1, h1 span{font-size:37px;}
      h1 img,h1 svg{width:28px;}
      h2{font-size:17px;}
    }
  }

  .hsFullImg{@include sec-default();position:relative;z-index:0;margin-bottom:50px;
    &::after{@include after();left:0;width:50%;height:40px;background-color:$bodybg;z-index:1;}

    @include bp(og){margin-bottom:30px; &::after{height:25px;}}
    @include bp(oc){ &::after{height:15px;}}
  }


  .hsecFloat{position:relative;z-index:1;padding:50px 0 0px;
    .sig{width:65%;max-width:670px;margin-left:-8%;
      p{font-family:$heading;font-size:65px;line-height:1.3em;margin-bottom:0px;}
      p span.two{float:right;}
      .cta{@include sec-default();text-align:right;}
      a{font-size:25px;color:$color2;font-weight:600;}
    }

    .print{width:37%;max-width:395px;margin-right:-4%; margin-top:3%;
      p{font-size:18px;}
    }

    @include bp(su){
      .sig{width:67%;max-width:700px;margin-left:-10%;}
      .print{margin-right:-6%;}
    }

    @include bp(tl){
      .sig{max-width:600px;margin-left:-4%;
        p{font-size:60px;}
      }
      .print{margin-right:-1%;margin-top:1%;}
    }

    @include bp(tw){
      .medDef{width:95%;}
      .sig{width:55%;max-width:550px;margin-left:1%;
        p{font-size:55px;}
        a{font-size:23px;}
      }
      .print{margin-right:1%;margin-top:0;}
    }

    @include bp(tb){padding:30px 0 0;
      .sig{max-width:480px;margin-left:2%;
        p{font-size:50px;}
        a{font-size:21px;}
      }
      .print{width:39%;margin-right:2%;
        p{font-size:17px;}
      }
    }

    @media (max-width:990px){padding-top:0px;
      .medDef{max-width:660px;}
      .sig{max-width:500px;width:100%;@include center-block();}
      .print{@include sec-default();max-width:100%;margin:20px 0 0;text-align:center;}
    }

    @include bp(og){
      .medDef{width:90%;}
      .sig{max-width:400px;text-align:center;
        p{font-size:40px;}
        a{font-size:18px;}

        p span.two{float:none;}
        .cta{text-align:center;margin-top:5px;}
      }
    }
    @include bp(xs){
      .medDef{width:95%;}
      .sig{max-width:400px;
        p{font-size:33px;}
        a{font-size:17px;}
      }
    }
  }
  .badge{position:absolute;z-index:5;border-radius:50%;background-color:$black; overflow:hidden;
    width:250px;height:250px;padding-top:70px; top:-140px;right:20px;
    
    h2{color:$white;width:80%;@include center-block();font-family:$heading;font-size:45px;} 
    h2 span{float:right;margin-right:5px;}
  
    .curveText{
      .curve,.curve2{position:absolute;left:0;right:0;font-size:22px;letter-spacing:5px;font-weight:300;text-transform:uppercase;  font-size:20px;}
      .curve{top:-8px;@include rotate(4deg);}
      .curve2{bottom:-8px;@include rotate(185deg);}
      path{fill:transparent;}
      text{fill:$white;opacity:.7;}
    }

    @include bp(tb){width:230px;height:230px;top:-130px;right:10px;
      h2{font-size:41px;} 
    }

    @include bp(br){text-align:center;
      h2{font-size:38px;}
      h2 span{float:none;margin:auto;}
    }

    @media (max-width:900px){width:200px;height:200px;padding-top:60px;top:-100px;
      h2{font-size:35px;}
    }

    @include bp(og){display:none;
      // position:relative;padding-top:0;top:auto;border-radius:0;background-color:transparent;height:100%;width:100%;
      // h2{font-size:19px;width:100%;color:$black;font-family:$body;font-weight:300;text-transform:uppercase;letter-spacing:2px;}
      // h2 br, .curveText{display:none;}
    }
  }


  .stackLay{padding-top:80px;
    .slWrap{@include center-block();width:97%;max-width:1700px;}
    .slWrapMobile,.msecImg{display:none;}
    
    .col{display:inline;margin-bottom:150px;position:relative;}
    .tmargin{margin-top:150px;}

    .left .iwrap{float:right;}
    .right .iwrap{float:left;}
    .sizeS{width:44%;
      .iwrap{max-width:500px;}
      .caption{width:90%;padding:0 20px 0 10px;}
    }
    .sizeL{width:53%; max-width:750px;
      .caption{width:80%;margin-left:3%;}
    }
    .grid100{
      .iwrap{width:90%;@include center-block();}
      .caption,.gridImg{display:inline-block;}
      .caption{width:30%;margin-top:14%;max-width:400px;}
    } //only sl2

    .caption{position:relative;z-index:1;}
    .gridImg{position:relative;z-index:0;
      &::after{@include abs();width:90%;left:0;height:40px;background-color:$bodybg;z-index:1;}
      &.top::after,&.both::after{top:25px;}
      &.btm::after{bottom:0;}
      
      &.both::before{@include abs();width:90%;left:0;bottom:0;height:40px;background-color:$bodybg;z-index:1;}
      
      &.lside{width:70%;float:right;
        &::after{width:30px;height:80%;top:10%;}
      }
      &.rside{width:70%;float:left;
        &::after{width:35px;height:60%;top:15%;left:auto;right:0;}
      }
    }


    p.hd2{font-size:28px;line-height:1.1em;margin-bottom:10px;text-transform:uppercase;letter-spacing:1px;font-weight:bold;font-family:$corm;
      padding-top:30px;padding-left:30px;
      span{font-size:47px;font-family:$heading;text-transform:none;font-weight:normal;letter-spacing:0;
        color:$color2;@include rotate(-15deg);position:absolute;margin-top:-40px;margin-left:-40px;
      }
      img.and{width:35px;display:inline;margin-top:-10px;padding:0 5px;}

      &.laserHd span,&.massageHd span{font-size:50px;}
      &.laserHd span,&.facialHd span{margin-top:-34px;}
    }
    
    p:not(.hd2){font-size:16.5px;}
    
    .textLink{font-weight:bold;color:$color3;letter-spacing:1px;float:right;margin-right:20px;
      font-size:16px;text-transform:uppercase; &::after{bottom:2px;} 
      // font-family:$heading;font-size:30px; &::after{height:5px;}
    }
    .arrow{width:25px;display:inline;padding:0 0 3px 2px;}

    .clearfix.customClear{display:none;}


    // only sl1
    .final{margin-bottom:0 !important;padding-left:10px;margin-top:20px;
      .iwrap{margin-right:-40px;}
      p{font-size:18px;}
      p.hd2{font-size:53px;}
      .text{width:95%;}
    }

    @media (max-width:1560px){
      .XXsizeL.adj, .sizeL{width:50%;} // ??
      .sizeL+.sizeL{width:47%;}

      &.lay2{
        .col{margin-bottom:100px;}
        .tmargin{margin-top:100px;}
      }
    }
  
    @media (max-width:1500px){br.vBr{display:none;}}

    @include bp(tl){.gridImg.rside::after{height:70%;top:12%;}}
    @include bp(tx){
      .grid100 .caption{margin-left:-3%;width:33%;}
      .gridImg.rside::after{width:65px;}
    }

    @include bp(tw){
      .grid100 .iwrap{width:95%;}
      .clearfix.customClear{display:block;}
      .laser{margin-top:80px;}
      
      .sizeS .caption{padding-left:20px;}
      .sizeL .caption{padding-left:10px;}

      .final{padding:0 10px;
        p.hd2{font-size:45px;}
      }

      &.lay1{
        .col{margin-bottom:100px;}
        .tmargin{margin-top:100px;}
      }

      &.lay2{
        .col{margin-bottom:70px;}
        .tmargin{margin-top:70px;}
      }      
    }

    @media (max-width:1080px){
      .gridImg.rside::after{top:7%;height:85%;}
      .grid100 .caption{margin-top:10%;}
    }
    @include bp(tb){
      .slWrap{width:92%;}

      // 
      p.hd2{font-size:23px;
        span{font-size:41px;margin-left:-35px;}
        &.laserHd span,&.massageHd span{font-size:47px;}
      }

      .grid100{
        .iwrap{width:100%;}
        .caption{margin-left:-6%;width:36%;margin-top:13%;}
      }
      .gridImg.rside::after{width:85px;}
    }
    @include bp(br){
      p.hd2{font-size:21px;
        span{font-size:36px;margin-top:-30px;}
        &.massageHd span{font-size:36px;}
        &.laserHd span{font-size:42px;}
        &.laserHd span,&.facialHd span{margin-top:-26px;}
      }


      &.OG{
      .gridImg:not(.rside)::after{display:none;}
      .sizeS,.sizeL{width:100%;max-width:100%;float: left !important;
        .iwrap{max-width:100%;}
        .caption,.gridImg{display:inline-block;vertical-align:top;}

        .caption{margin:5% 0 0;padding:30px 0;
          &::after{@include abs();width:25px;height:100%;top:0;background-color:$bodybg;}
        }
      }

      .btmCap .caption::after{left:-25px;}
      .topCap .caption::after, .grid100 .caption::after{right:-25px;}

      .sizeS{
        .caption{width:63%;}
        .gridImg{width:37%;}

        &.left .caption{padding-right:70px;}
        &.right .caption{padding-left:70px;}
      }
      .sizeL{
        .caption{width:53%;}
        .gridImg{width:47%;}

        &.left .caption{padding-right:50px;}
        &.right .caption{padding-left:50px;}
      }

      .grid100 .caption{width:44%;margin-left:-14%;}
      .gridImg.rside::after{width:160px;}
      }

      .slWrap{max-width:700px;}
      .col{width:100%;float:left !important;max-width:100% !important;z-index:0;padding:40px 0;
        &::after{@include after();width:50%;background-size:cover;background-repeat:no-repeat;background-position:center center;}

        &.btmCap::after, &.grid100::after{left:0;}
        &.topCap::after{right:0;}


        &.facialG::after{background-image:url('../../assets/images/facialGrid.jpg');background-position:left center;}
        &.massageG::after{background-image:url('../../assets/images/massageGrid.jpg');background-position:right center;}
        &.laserG::after{background-image:url('../../assets/images/laserGrid3.jpg');background-position:right center;}
        &.browG::after{background-image:url('../../assets/images/eyebrowGrid.jpg');background-position:right center;}
        &.cosmeticG::after{background-image:url('../../assets/images/cosmeticGrid.jpg');}
      }

      .iwrap{width:60% !important;max-width:100% !important;}
      .btmCap .iwrap, .grid100 .iwrap{float:right;}
      .topCap .iwrap{float:left;}
      .gridImg, .gridImg.rside{display:none;}
      .caption,.grid100 .caption{background-color:$bodybg;padding:30px 20px 20px !important;width:100% !important;max-width:100% !important;margin:0 !important;float:left;}

      p:not(.hd2){margin-bottom:10px;}
      .textLink{z-index:1;font-size:14px;}

      &.lay1,&.lay2{
        .col{margin-bottom:70px;}
        .tmargin,.laser{margin-top:0px;}
      }
    }

    @include bp(og){padding-top:20px;
      &.OG{
      .slWrap{max-width:600px;}
      .col{overflow:hidden;margin-bottom:30px !important;
        .iwrap{@include sec-default();}
        .gridImg{position:absolute;width:100%;top:0;left:0;right:0;bottom:0;}
        .caption{width:100%;padding:25px 22px 25px 25px !important;margin:0;
          &::after{background-color:rgba($bodybg,.7);width:100%;left:0 !important;}
        }
      }
      }
      
      p.hd2 span{margin-left:-30px;}
      .iwrap{width:70% !important;}


      .sizeS.laserG{margin-bottom:40px;}
    }
    @include bp(oc){padding-top:0;
      .slWrap{display:none;}
      .slWrapMobile{@include center-block();width:90%;}
      .msecImg{@include center-block();width:95%;margin-bottom:10px;}
      .accordion{@include sec-default();padding-bottom:30px;
        li{margin-bottom:6px; border-bottom:$border;}
        // li:last-child{border-bottom:none;
        //   .accContent{padding-bottom:0;}
        // }
      }
      .accTitle,li{@include sec-default();}
      .accContent{width:100%;float:left;margin-top:0;padding-bottom:10px;}
      button{padding:0;text-align:left;}
      .accImage{@include sec-default();margin-top:20px;}

      p.hd2{padding:0;margin-bottom:5px;
        span{@include rotate(0);position:relative;margin:0;line-height:1.2em;}
      }
      .iwrap{width:85% !important;}
      img{width:85%;@include center-block();}
      .textLink{float:left;}
    }
    @include bp(sm){      
      p.hd2{font-size:19px;
        span{font-size:31px;}
        &.massageHd span{font-size:33px;}
        &.laserHd span{font-size:39px;}
      }
    }
    @include bp(xs){      
      p.hd2{font-size:18px;
        span{font-size:27px;}
        &.massageHd span{font-size:29px;}
        &.laserHd span{font-size:33px;}
      }
    }
  }


  .banner{padding:40px 0 30px;background-color:$dark3;text-align:center;position:relative;z-index:0;
    p{color:$white;}
    p.quote{font-size:21px;
      span{font-size:30px;}
    }
    p.cat{letter-spacing:3px;}

    @include bp(tb){
      .med2Def{max-width:800px;}
    }
    @include bp(br){
      .qwrap{max-width:800px;}
      p.quote{font-size:19px;
        span{font-size:25px;}
      }
      p.cat{font-size:14px;}
    }
    @include bp(tn){
      p.quote{font-size:18px;
        span{font-size:23px;}
      }
    }

    @include bp(og){
      p.quote{font-size:17px;
        span{font-size:21px;}
      }
      p.cat{font-size:13px;}
    }
  }

  .about{margin-bottom:120px;position:relative;//padding:100px 0 0;
    .medDef{max-width:100%;}
    // .imgSide{float:left;}
    // .textSide{float:right;margin-top:14%;}

    .text{@include sec-default();
      p{font-size:18px;max-width:441px;}
    }
    // .item2{position:absolute;width:100%;max-width:590px;margin-top:24.5%;
    //   right:25px;text-align:right;
    //   p{color:$color2;font-size:42px;line-height:1.2em;}
    // }



    padding:50px 0 0;
    .imgSide{float:right;}
    .textSide{float:left;margin-top:14%;margin-left:5%;}
    .item2{position:absolute;width:100%;max-width:590px;margin-top:24.5%;left:40px;
      p{color:$color2;font-size:42px;line-height:1.2em;}
    }





    @media (min-width:1801px){position:relative;  padding:100px 0 0; 
      .medDef{max-width:1700px;}
      // .item2{bottom:100px;margin-top:auto;right:auto;left:65%;}
      .item2{bottom:100px;margin-top:auto;left:70px;}
    }

    @media (max-width:1550px){  padding-top:20px;
      .textSide{width:37%;}
      .imgSide{width:57%;}
      .item,.item2{max-width:575px;   left:30px;
        p{font-size:40px;}
      }
    }

    @include bp(tl){
      .textSide{margin-top:12%;  margin-left:2%;}
      .item,.item2{max-width:550px; //right:15px;
        p{font-size:37px;}
      }
      .item2{margin-top:22.8%;}
    }

    @include bp(tx){
      .textSide{margin-top:10%;}
      .item{margin-top:14%;}
      .item2{margin-top:23%;}
    }

    @include bp(tw){  margin-bottom:90px;
      .medDef{width:95%;}
      .textSide{width:40%;margin-top:8%;}//margin-right:1%;}
      .imgSide{width:54%;}
      .text p{max-width:100%;font-size:17px;}
      .item p,.item2 p{font-size:34px;}
    
      .item2{margin-top:21.5%;}
    }

    @include bp(tb){
      .textSide{margin-top:7%;}//margin-right:0;}
      .imgSide{width:56%;}
      .item{margin-top:17%;}
      .item2{margin-top:24%;
        p{text-shadow:0px 1px $white;}
      }
    }

    @media (max-width:959px){  margin-bottom:30px;  //padding:60px 0 20px;margin-bottom:0px;
      .medDef{max-width:740px;}
      .textSide{width:42%; margin-left:0;}//margin-left:-15px;}
      .imgSide{width:55%;}
      .item2{float:left;display:block;position:relative;margin-top:30px;max-width:100%;text-align:center; left:auto;}//right:auto;}
    }

    @include bp(og){  padding-top:0;margin-bottom:40px; //padding:20px 0 30px;
      .medDef{max-width:600px;width:90%;}
      .textSide,.imgSide{@include sec-default();text-align:center;}
      .textSide{margin:0 0 15px;}
      img{width:99.9%;max-width:400px;@include center-block();}
      .item2{display:none;}
    }
  }
}