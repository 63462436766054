// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.Contact{@include sec-default();padding-bottom:30px;
  @include bp(oc){padding-bottom:10px;}

  .ServiceHeaderSimple{@include sec-default();padding:100px 0 20px;
    h1{line-height:1.3em;margin-bottom:0;max-width:750px;color:$gray;
      span{float:right;}
    }

    @include bp(tl){
      .medDef{max-width:950px;}
      h1{@include center-block();}
    }
    @include bp(tw){h1{font-size:55px;}}
    @include bp(tb){padding-top:80px;
      h1{font-size:50px;}
    }

    @include bp(br){padding-top:50px;
      .medDef{max-width:700px;}
      h1{font-size:45px;max-width:100%;width:90%;}
    }

    @include bp(og){padding:30px 0 10px;
      .medDef{max-width:500px;}
      h1{font-size:35px;line-height:1.1em;width:100%;}
    }

    @include bp(oc){text-align:center;
      .medDef{max-width:420px;}
      h1{font-size:33px;
        span{float:none;}
      }
      br{display:none;}
    }

    @media (max-width:350px){h1{font-size:30px;}}
  }


  @include bp(oc){.fullImg .insideXl{width:100%;}}

  .customWrap{
    .text,p.cat span{background-color:$dark3;}

    .XmedDef{width:80%;float:left;}
    .text{@include sec-default();position:relative;z-index:0;margin-top:-80px;padding:60px 0;}
    .innerText{width:95%;max-width:680px;@include center-block();}
    .item{@include sec-default();margin-bottom:40px;}


    p{color:$white;position:relative;}
    p:not(.cat){font-size:18px;}
    p.cat{font-size:15px;
      &::after{@include before();height:49.9%;border-bottom:1px solid rgba($white,.4);}
      span{padding-right:15px;}
    }
    
    .itLast{margin:20px 0 0;text-align:center;
      // p.cat::after{width:100%;}
      p.cat{margin-bottom:10px;}
      p.cat::after{width:70%;left:15%;bottom:-5px;top:auto;}
      p.cat span{padding-left:15px;}
    }
    p.full{@include sec-default();}
    span.smText{font-size:.9em;font-style:italic;}
    li{margin-left:-3px !important;}
    svg{color:$white !important;width:20px;height:20px;}
    .textLink{color:$white;font-size:15.5px;letter-spacing:1px;
      &::after,&::before{background-color:$white;}
      &:hover{color:$white;}
    }
    br.ocShow,br.hideMe{display:none;}

    @include bp(tw){
      .XmedDef{width:90%;}
      .text{margin-top:-70px;}
    }

    @include bp(br){
      .text{margin-top:-50px;}
      .innerText{max-width:600px;}
      .left,.right{width:44%;}
      p:not(.cat){font-size:17px;}
      svg{width:17px;height:17px;}
    }

    @include bp(tn){.XmedDef{width:95%;}}

    @include bp(oc){
      .XmedDef{width:100%;}
      .left,.right{width:100%;@include center-block();max-width:280px;}
      .item{margin-bottom:15px;}
      br.ocShow{display:block;}

      .text{background-color:transparent;margin-top:0;padding:25px 0 0;}
      p{color:$black;}
      svg{color:$black !important;}
      .textLink{color:$black;letter-spacing:0;
        &::after,&::before{background-color:$black;}
        &:hover{color:$black;}
      }
      p.cat span{background:$bodybg;}
      p.cat::after{border-color:$black01;}

      .itLast{margin:0;padding-top:10px;background-color:rgba($navy,.1);
        p.cat span{background:transparent;}
        p.cat::after{display:none;}
      }
    }

    @include bp(fi){
      .itLast{padding-left:5px;padding-right:5px;
        br.info{display:none;}
      }
    }
  }
}