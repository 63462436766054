@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';



.Footer.plain .ftWrapServe{display:none;}
.Footer{@include sec-default();position:relative;z-index:0;
  .ftWrapServe{padding-top:40px;position:relative;z-index:0;padding-bottom:15px;
    &::after{@include abs();width:60%;left:20%;height:1px;bottom:0;border-top:$border;}
  }
  
  // .left{max-width:320px;margin-left:-2%;}
  // .right{max-width:240px;margin-right:-5%;}
  // .mid{max-width:345px;margin-left:5%;}
  .left{max-width:318px;margin-left:-3%;
    .item{margin-left:-5%;}
  }
  .mid{max-width:345px;margin-left:2%;}
  .right{max-width:250px;margin-right:-2%;}


  .bar{@include sec-default();  display:none;  background-color:$color3;text-align:center;padding:10px 0;margin-bottom:40px;
    li{margin:0 10px;
      &::before{background-color:$white;top:48%;right:-10px;}
      &:last-child::before{display:none;}
    }
    a{color:$white;font-size:15px;}
  }
  

  .item{@include sec-default();}
  .textLink{font-size:14.5px;}
  p:not(.cat){font-size:15px;}
  p.cat,span.cat{letter-spacing:3px;font-size:14px;}
  p.ap1{margin-bottom:5px;}
  p.fsLink a{color:$color2;}
  span.smText{font-size:14px;font-style:italic;}


  .item li{margin-left:0;
    svg{width:15.5px;height:15.5px;margin-top:-3px;}
  }

  .btmFooter{padding:20px 0 10px;text-align:center;}
  p.copy{display:inline-block;font-size:13px;font-weight:300;margin:5px 0 0;line-height:1.5em;
    a{color:$color3;}
  }
  
  // &.DefaultStyle .med4Def{max-width:560px;padding-left:100px;}
  &.HomeStyle{background-color:#faf4f8;background-color:#faf5f8;}


  // @include bp(su){
    // .left{margin-left:-6%;}
    // .right{margin-right:-9%;}
    // .mid{margin-left:9%;}
  // }

  @media (max-width:1100px){
    // .left{margin-left:-3%;}
    // .right{margin-right:-3.5%;}

    .left .item{margin-left:0;}
  }
  // @include bp(tb){
    // .left{margin-left:-1%;}
    // .mid{margin-left:4%;}
  // }
  @include bp(br){
    .ftWrapServe::after{display:none;}
    .med2Def.v1{max-width:580px;}
    .left{margin-left:0;}
    .right{margin-right:-1%;}
    .mid{@include sec-default();text-align:center;max-width:100%;margin:0;
      .i1{margin-top:20px;margin:15px 0 20px;padding:25px 0 10px;border-top:$border;border-bottom:$border;}
      .i2{margin-bottom:10px;padding-bottom:10px;border-bottom:$border;}

      // p{max-width:450px;@include center-block();}
    }

    .btmFooter{padding-top:0;}
    // &.DefaultStyle .med4Def{padding-left:0px;}
  }

  @include bp(oc){
    .ftWrapServe{padding-top:20px;}
    .left,.right{@include center-block();text-align:center;}
  }
}