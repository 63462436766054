@import './variables.scss';
@import './mixins.scss';

h1, h2, h3, h4, h5, p{
  &.color1, span.color1{color:$color1;}
  &.color2, span.color2{color:$color2;}
}

p.title{font-size:70px;font-family:$heading;letter-spacing:2px;line-height:1em;}//font-weight:bold;}
p.hd{font-size:20px;}

@font-face {
  font-family: 'Strings';
  src: url('./fonts/Strings.ttf');
  src: //url('./fonts/Strings.eot?#iefix') format('embedded-opentype'),
       url('./fonts/Strings.otf'),
       url('./fonts/Strings.woff') format('woff'),
       url('./fonts/Strings.woff2') format('woff2');
}

.inside, .insideAlt, .medDef, .med2Def, .med3Def, .med4Def, .insideXl, .insideN, .insideNrw, .insideTw, .custWrap{@include center-block();width:90%;}
.inside, .insideAlt, .insideXl, .insideN{max-width:1700px;}
.insideXl{width:95%;}
.inside{width:85%;}
.insideAlt{width:80%;}
.insideTw{max-width:1200px;}
.medDef{max-width:1080px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.med4Def{max-width:650px;}
@media screen and (min-width:823px){.insideNrw{max-width:820px;}}
@include bp(tb){.medDef, .med2Def, .med3Def, .med4Def, .insideNrw, .insideTw, .custWrap{width:92%;}}


.fullRow,.kpGrid{@include sec-default();
  // .flexWrap{display:flex;align-items:center;justify-content:space-around;}
  .hlf,.hlf1,.hlf2,.col,.left,.right{display:inline-block;vertical-align:top;}
  .hlf1,.col1,.left{float:left;}
  .hlf2,.col3,.right{float:right;}
  .left.pull{margin-left:-3%;}
  .right.pull{margin-right:-3%;}
  .cblock{@include center-block();}

  // .equal{width:47%;}
  // .small{width:33%;}
  // .large{width:65%;}

  .grid5{width:5%;}
  .grid15{width:15%;}
  .grid20{width:20%;}
  .grid21{width:21%;}
  .grid22{width:22%;}
  .grid23{width:23%;}
  .grid24{width:24%;}
  .grid25{width:25%;}
  .grid26{width:26%;}
  .grid27{width:27%;}
  .grid28{width:28%;}
  .grid29{width:29%;}
  .grid30{width:30%;}
  .grid31{width:31%;}
  .grid32{width:32%;}
  .grid33{width:33%;}
  .grid34{width:34%;}
  .grid35{width:35%;}
  .grid36{width:36%;}
  .grid37{width:37%;}
  .grid38{width:38%;}
  .grid40{width:40%;}
  .grid42{width:42%;}
  .grid43{width:43%;}
  .grid44{width:44%;}
  .grid45{width:45%;}
  .grid46{width:46%;}
  .grid47{width:47%;}
  .grid48{width:48%;}
  .grid49{width:49%;}
  .grid50{width:50%;}
  .grid51{width:51%;}
  .grid52{width:52%;}
  .grid53{width:53%;}
  .grid54{width:54%;}
  .grid55{width:55%;}
  .grid56{width:56%;}
  .grid57{width:57%;}
  .grid58{width:58%;}
  .grid59{width:59%;}
  .grid60{width:60%;}
  .grid62{width:62%;}
  .grid63{width:63%;}
  .grid64{width:64%;}
  .grid65{width:65%;}
  .grid66{width:66%;}
  .grid67{width:67%;}
  .grid68{width:68%;}
  .grid69{width:69%;}
  .grid70{width:70%;}
  .grid72{width:72%;}
  .grid73{width:73%;}
  .grid75{width:75%;}
  .grid80{width:80%;}
  .grid82{width:82%;}
  .grid85{width:85%;}
  .grid90{width:90%;}
  .grid100{@include sec-default();}


  // *** KPKP - DO WE WANT TO BE SO STRICT HERE ?
  @include bp(br){
    .medDef:not(.mediaCust){max-width:680px;}
    .medDef:not(.mediaCust) .left, .medDef:not(.mediaCust) .right,&.tbStack .left, &.tbStack .right{width:100% !important;}
  }

  // @include bp(og){
  //   &.mobile0,&.defaultOG{
  //     .hlf1,.hlf2,.col{@include sec-default();}
  //   }
  // }
}


.centerBlock{@include center-block();}
.bmargin0{margin-bottom:0;}
.bmargin05{margin-bottom:5px;}
.bmargin1{margin-bottom:10px;}
.tmargin0{margin-top:0;}
.tmargin2{margin-top:20px;}
.textCenter{text-align:center;}
.textLeft{text-align:left;}
.textRight{text-align:right;}
.tpadding4{padding-top:40px;}
.tpadding6{padding-top:60px;}

.spacer1,.spacer2,.spacer25,.spacer3,.spacer4,.spacer5{@include sec-default();}
.spacer1{height:10px;}
.spacer2{height:20px;}
.spacer25{height:25px;}
.spacer3{height:30px;}
.spacer4{height:40px;}
.spacer5{height:50px;}

p.cat, span.cat{text-transform:uppercase;font-weight:800;letter-spacing:5px;font-size:15px;margin-bottom:0px;}
span.em,p.em{font-style:italic;}

hr{border-color:rgba($black,.1);
	&.hlf,&.hlfC{width:50%;}
  &.hlfC, &.center{@include center-block();}
	&.less{width:25%;}
	&.l120{width:120px;}
}
.clearfix::after, .clearfixBtn::after,.clearfixBtn2::after {content:"";clear:both;display:table;}
.clearfix.btn, .clearfixBtn{@include sec-default();margin-top:20px;}
// .clearfixBtn2{@include sec-default();margin-top:10px;}
.clearBorder, .clearBorderWhite, .clearBorderLeft{@include sec-default();height:50px;position:relative;z-index:0;margin:0px 0 20px;
  &::before{@include before();width:1px;left:49.7%;background-color:rgba($black,.2);}
}
.clearBorderWhite::before{background-color:rgba($white, .3);}
@include bp(br){
  .clearfix.brSpace1{margin-bottom:10px;}
  .clearfix.brSpace2{margin-bottom:20px;}
}
.visuallyHidden {@include visuallyHidden();}
.imgResponsive {max-width:100%;height:auto;display:block;}

.spaLink,.spaLink2,.spaLinkRev,.spaLink2Rev,.spaLinkSm,.spaLinkRed,.spaLinkXl,.regBtn{@include link($color1, $white);padding:8px 25px;
  box-shadow:0 10px 25px rgba($black,.15);letter-spacing:.6px;text-transform:uppercase;
  &.inline{margin:3px;}
  &.xl{width:100%;@include sec-default();}
  &:hover{color:$white !important;}
  // @include bp(tb) {font-size:15px;}
}
.spaLinkSm{padding:5px 20px 7px;}
.spaLink2{background-color:$color2;color:$white;text-shadow:none;
  &:hover{color:$white !important;}
}
.spaLinkRev{background-color:$white;color:$color1;text-shadow:none;border:1px solid $color1;
  &:hover{color:$color1 !important;}
}
.spaLink2Rev{background-color:transparent;color:$color2;text-shadow:none;border:1px solid $color2;
  &:hover{color:$color2 !important;}
}

.spaLinkRed{background-color:$red;}
.regClear{height:15px;}
@media (max-width:767px){
	.regBtn{font-size:14px;}
	.regClear{height:10px;}
}

.textLink{color:$color2;font-weight:600;position:relative;
  &::after,&::before{@include after();left:0;background-color:$color2;opacity:.1;height:7px;}//bottom:1px;height:4px;}
  &::before{width:0%;transition:width 0.5s ease;}
  &:hover{color:$color2;opacity:1;}
  &:focus::before, &:hover::before{width:100%;opacity:.3;}
}

ul.disc li{list-style-type:disc;}
.dotList{
  li{display:inline-block;position:relative;z-index:0;padding:0 10px;}
  li::before{@include abs();width:4px;height:4px;top:35%;right:-2px;background-color:$black;border-radius:50%;}
}
