@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@300;400;600;800&family=Cormorant:wght@800&display=swap');
@import './assets/global';
@import './assets/variables';
@import './assets/mixins';

*, *::before, *::after {box-sizing:border-box;}
body{margin:0;}
html{line-height:1.6em;font-size:17px;color:$black;font-family:$body;font-weight:normal;background-color:$bodybg;}
h1, h2, h3, h4, h5, p{margin:0 0 15px;font-family:$body;color:$black;}
p{font-size:17px;font-weight:300;line-height:1.6em;}
a{text-decoration:none;
  &:hover{opacity:.8;text-decoration:none;@include ease(.3s);}
}
button{background:none;border:none;border-radius:0;color:inherit;font-weight:inherit;font-family:inherit;font-size:inherit;letter-spacing:inherit;
  &:hover{cursor:pointer;color:currentColor;}
}
img{@include center-block();}
ul {margin:0;padding-left:0;}
li{list-style-type:none;}
h1{font-family:$heading;font-size:60px;line-height:1em;}

.rejuvWrap{@include sec-default();
  // position:relative;&::before{@include before();background-image:url('./assets/images/IMAGE.png');background-size:100%;z-index:-2;opacity:.1;}
  //// &::after{@include after();left:0;background-color:rgba($bodybg,.9);}
  //// &.skincare::before,&.cosmeticenhancements::before,&.massage::before,&.broweyelash::before,&.laser::before{display:none;}
}
.MainContent{@include sec-default();
  @media (min-width:992px) {min-height:55vh;}
  @media (min-width:1024px) {min-height:74vh;}
}

.ToTopBtn{position:fixed;width:30px;height:30px;right:10px;bottom:30px;text-align:center;background-color:$black;color:$white !important;border:1px solid rgba($white,.6);z-index:5;
  &:hover{color:$white !important;background-color:$color2;@include ease(1s);}


  span{@include sec-default();padding:0;color:$white !important;}//margin-bottom:5px;}
  svg{width:24px;height:24px;color:$white !important;}
  // span{font-size:20px;}
  // svg{margin-bottom:4px;}
  // @include bp(tl) {bottom:50px !important;right:15px !important;}
  // @include bp(tb) {bottom:15px !important;}
  // @include bp(oc) {bottom:45px !important;}
}